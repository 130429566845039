(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-overview-selector/assets/javascripts/coupon-overview-selector.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-overview-selector/assets/javascripts/coupon-overview-selector.js');
"use strict";

const {
  useSelector
} = ReactRedux;
const {
  CouponOverviewRows
} = svs.components.tipsen.couponOverviewRows;
const {
  useCouponContext
} = svs.components.tipsen.couponComponents;
const {
  CouponOverviewSingleRows
} = svs.components.tipsen.couponOverviewSingleRows;
const {
  CouponOverviewExternal
} = svs.components.tipsen.couponOverviewExternal;
const {
  selectIsCouponSingleRows,
  selectIsCouponExternal
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  clx
} = svs.components.lbUtils;
const CouponOverview1X2 = _ref => {
  let {
    children,
    classNames
  } = _ref;
  if (!children) {
    return null;
  }
  return React.createElement("div", {
    className: classNames
  }, children);
};
const CouponOverviewSelector = _ref2 => {
  let {
    isCompact = false,
    style = {}
  } = _ref2;
  const {
    couponId
  } = useCouponContext();
  const isSingleRows = useSelector(state => selectIsCouponSingleRows(state, couponId));
  const isExternal = useSelector(state => selectIsCouponExternal(state, couponId));
  const blockName = 'pg_coupon_overview_selector';
  const classNames = clx(blockName, "".concat(blockName, "--show-all"), {
    'margin-xs-h-2': !isCompact
  });
  if (isCompact && isExternal) {
    return React.createElement(CouponOverview1X2, {
      classNames: classNames
    }, React.createElement(CouponOverviewExternal, null));
  } else if (isCompact && isSingleRows) {
    return React.createElement(CouponOverview1X2, {
      classNames: classNames
    }, React.createElement(CouponOverviewSingleRows, {
      style: style
    }));
  }
  return React.createElement(CouponOverview1X2, {
    classNames: classNames
  }, React.createElement(CouponOverviewRows, {
    style: style
  }));
};
setGlobal('svs.components.tipsen.couponOverviewSelector.CouponOverviewSelector', CouponOverviewSelector);

 })(window);